<template>
    <div class="pt-32 flex flex-col items-center" v-if="user">
        <div class="w-full px-16 mb-8" v-if="myClasses.length && !showSmall">
            <my-month-calendar :class-list="myClasses" :lang-code="langCode" @go-to-class="goToClass" />
        </div>
        <div class="w-full mb-8" v-if="myClasses.length && showSmall">
            <my-month-sessions :class-list="myClasses" :lang-code="langCode" />
        </div>
        <div class="flex flex-wrap justify-center">
            <van-cell-group class="w-80 p-2 my-4 shadow-md" inset v-if="!isStudent">
                <div class="text-center py-4">
                    <van-button type="primary" to="/viewStudents">
                        <van-icon name="friends-o" size="36px" />
                    </van-button>
                </div>
                <van-cell :title="lang[langCode].titleStudent" :value="stats.totalStudent" />
                <van-cell :title="lang[langCode].titleStudentActive" :value="stats.totalActiveStudent" />
                <van-cell :title="lang[langCode].titleStudentNew" :value="stats.recentStudents"
                    value-class="text-blue-500" />
            </van-cell-group>
            <van-cell-group class="w-80 p-2 my-4 shadow-md" inset v-if="isManager">
                <div class="text-center py-4">
                    <van-button type="primary" to="/viewTeachers">
                        <van-icon name="manager-o" size="36px" />
                    </van-button>
                </div>
                <van-cell title="总教师" :value="stats.totalTeacher" />
                <van-cell title="在职教师" :value="stats.totalActiveTeacher" />
            </van-cell-group>
            <van-cell-group class="w-80 p-2 my-4 shadow-md" inset>
                <div class="text-center py-4">
                    <van-button type="primary" to="/viewClassList">
                        <van-icon name="shop-o" size="36px" />
                    </van-button>
                </div>
                <van-cell :title="lang[langCode].titleClass" :value="stats.totalClass" />
                <van-cell :title="lang[langCode].titleClassStart" :value="stats.startedClass" />
            </van-cell-group>
            <van-cell-group class="w-80 p-2 my-4 shadow-md" inset v-if="isSuperManager">
                <div class="text-center py-4">
                    <van-button type="primary" to="/viewManagers">
                        <van-icon name="cluster-o" size="36px" />
                    </van-button>
                </div>
                <van-cell title="总管理员" value="0" />
            </van-cell-group>
        </div>
    </div>
</template>

<script>
import lang from "@/langs/PageHomeLang.js";
import {
    onMounted,
    onUnmounted,
    computed,
    reactive,
    ref
} from "vue";
import {
    useStore
} from "vuex";
import {
    useRouter,
    useRoute
} from "vue-router";
import {
    Image as VanImage,
    Cell,
    CellGroup,
    Button,
    Icon
} from "vant";
import Env from "@/logics/Envs.js";
import UserTypes from "@/static_values/StaticUserTypes.js";
import MyMonthCalendar from "@/components/MyMonthCalendar.vue";
import MyMonthSessions from "@/components/MyMonthSessions.vue";
import GetSession from "@/asyncs/GetSession.js";
import LoadStats from "@/asyncs/LoadStats.js";
import ListClass from "@/asyncs/ListClass.js";

export default {
    components: {
        [VanImage.name]: VanImage,
        [Cell.name]: Cell,
        [CellGroup.name]: CellGroup,
        [Button.name]: Button,
        [Icon.name]: Icon,
        MyMonthCalendar,
        MyMonthSessions
    },
    setup() {
        const store = useStore();
        store.commit("hideBack");
        store.commit("showMenu");
        const router = useRouter();
        const route = useRoute();
        const myClasses = ref([]);
        const stats = reactive({});
        onMounted(() => {
            GetSession({
                store,
                router,
                route
            }, async function () {
                store.commit("setPageTitle", lang[store.state.lang].title);
                // console.log("start waiting...");
                // await new Promise(resolve => setTimeout(resolve, 1000));
                // console.log("waiting finished");
                const [list] = await ListClass();
                if (!list) {
                    return;
                }
                myClasses.value = list;
                if (store.state.user.userType === UserTypes.student.value) {
                    if (list.length === 0) {
                        console.log("student has no class, go to find some");
                        router.push("/class/" + store.state.school.code)
                    }
                }

                LoadStats(stats);
                window.addEventListener("resize", onWindowSize);
                onWindowSize();
            });
        });

        const showSmall = ref(true);
        const onWindowSize = function () {
            //console.log(window.innerWidth);
            if (window.innerWidth <= 768) {
                showSmall.value = true;
            } else {
                showSmall.value = false;
            }
        };

        onUnmounted(function () {
            window.removeEventListener("resize", onWindowSize);
            console.log("onUnmounted");
        });

        const goToClass = function (classId) {
            router.push("/classDetails/" + classId)
        };

        return {
            lang,
            langCode: computed(() => store.state.lang),
            Env,
            user: computed(() => store.state.user),
            stats,
            isManager: computed(() => UserTypes.manager.value === store.state.user.userType),
            isStudent: computed(() => UserTypes.student.value === store.state.user.userType),
            isSuperManager: computed(() => store.state.user.super),
            myClasses,
            showSmall,
            goToClass
        };
    }
};
</script>
