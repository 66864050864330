import axios from "axios";
import CheckHttp from "@/asyncs/MyHttpCheck.js";
import MessageChecker from "@/logics/MessageChecker.js";
import Env from "@/logics/Envs.js";

export default async function ({ store, router, route }, nextAsyncCall, suppress) {
    try {
        if (store.state.user) {
            if (nextAsyncCall) {
                await nextAsyncCall();
            }
            return;
        }
        const res = await axios.get(Env.apiPath + "getSession");//Client sends JWT for decode
        if (!res.data.error) {
            store.commit("setUser", res.data.user);
            store.commit("setSchool", res.data.school);
            store.commit("setTimer", MessageChecker(store, route));
            if (nextAsyncCall) {
                await nextAsyncCall();
            }
        }
    } catch (err) {
        if (!suppress) {
            console.log(router);
            store.commit("setLastPage", window.location.href);
            CheckHttp(err);
        }
    }
}