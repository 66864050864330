import axios from "axios";
import Env from "@/logics/Envs.js";

export default function (stats) {
    axios.get(Env.apiPath + "schoolStats").then(function (res) {
        if (res.data.error) {
            stats.error = res.data.error;
            return;
        }
        stats.totalTeacher = res.data.totalTeacher;
        stats.totalActiveTeacher = res.data.totalActiveTeacher;
        stats.totalStudent = res.data.totalStudent;
        stats.totalActiveStudent = res.data.totalActiveStudent;
        stats.recentStudents = res.data.recentStudents;
        stats.totalClass = res.data.totalClass;
        stats.startedClass = res.data.startedClass;
    });
}