<template>
<v-calendar class="rounded-md w-full shadow-md" :masks="masks" :attributes="attributes" disable-page-swipe :firstDayOfWeek="1" @update:to-page="toMonth" :locale="langCode">
    <template v-slot:day-content="{ day, attributes }">
        <div class="my-cell">
            <span class="text-xs ml-1 text-gray-900">{{ day.day }}</span>
            <div class="my-event-area px-1">
                <div v-for="attr in attributes" :key="attr.key" class="rounded text-xs p-1 text-white mb-1 flex cursor-pointer" :class="attr.customData.colorClass" @click="clickSession(attr.customData)">
                    <div class="pr-1">{{ attr.customData.time }}</div>
                    <div>{{ attr.customData.title }}</div>
                </div>
            </div>
        </div>
    </template>
</v-calendar>
</template>

<script>
import {
    ref
} from "vue";
import GetDays from "@/logics/GetDaysOfWeekInMonth.js";
import Helpers from "@/logics/Helpers.js";
import moment from "moment";

export default {
    props: {
        classList: Array,
        langCode: {
            type: String,
            default: "zh"
        }
    },
    setup(props, {
        emit
    }) {
        const masks = {
            weekdays: "WWW"
        };

        const colors = ["bg-pink-500", "bg-pink-600", "bg-pink-700",
            "bg-gray-500", "bg-gray-600", "bg-gray-700",
            "bg-red-500", "bg-red-600", "bg-red-700",
            "bg-yellow-500", "bg-yellow-600", "bg-yellow-700",
            "bg-green-500", "bg-green-600", "bg-green-700",
            "bg-blue-500", "bg-blue-600", "bg-blue-700",
            "bg-indigo-500", "bg-indigo-600", "bg-indigo-700",
            "bg-purple-500", "bg-purple-600", "bg-purple-700"
        ];
        const attributes = ref([]);
        let monthPick = 0;
        const toMonth = function (page) {
            console.log(page);
            monthPick = page.month; //month: 1...12
            attributes.value = [];
            props.classList.forEach(function (classData) {
                if (!classData.teachers.length) {
                    return;
                }

                const courseStart = moment(new Date(classData.classDates.start.year, classData.classDates.start.month - 1, classData.classDates.start.day));
                const courseEnd = moment(new Date(classData.classDates.end.year, classData.classDates.end.month - 1, classData.classDates.end.day));

                if (!classData.classTimes.length) {
                    // const cursor = moment(new Date(classData.classDates.start.year, classData.classDates.start.month - 1, classData.classDates.start.day));
                    // const nextMonth = moment(new Date(classData.classDates.end.year, monthPick, 1));
                    // while (cursor.isBetween(courseStart, courseEnd, undefined, "[]") &&
                    //     cursor.isBefore(nextMonth)) {
                    //     attributes.value.push({
                    //         customData: {
                    //             title: classData.className,
                    //             classId: classData.classId,
                    //             time: "全天",
                    //             colorClass: ["bg-black"],
                    //             order: cursor.date() * 9999
                    //         },
                    //         dates: new Date(cursor.year(), cursor.month(), cursor.date())
                    //     });
                    //     cursor.add(1, "d");
                    // }
                    console.log(monthPick);
                    return;
                }

                GetDays(classData.classTimes, page.year, page.month, function (day, slot) {
                    if (!moment(new Date(page.year, page.month - 1, day)).isBetween(courseStart, courseEnd, undefined, "[]")) {
                        return;
                    }

                    const hour = Math.floor(slot.timeStart / 100);
                    const colorClass = [];
                    colorClass.push(colors[hour]);
                    attributes.value.push({
                        customData: {
                            title: classData.className,
                            classId: classData.classId,
                            time: Helpers.makeTimeText(slot.timeStart),
                            duration: slot.duration,
                            colorClass,
                            order: day * 10000 + slot.timeStart
                        },
                        dates: new Date(page.year, page.month - 1, day)
                    });
                });
            });
            attributes.value.sort(function (a, b) {
                return a.customData.order - b.customData.order;
            });
        };

        const clickSession = async function (data) {
            emit("goToClass", data.classId);
        };

        return {
            masks,
            attributes,
            clickSession,
            toMonth
        };
    }
}
</script>

<style scoped>
.my-event-area {
    overflow-y: scroll;
    height: 75px;
    scrollbar-width: none;
}

.my-cell {
    text-align: left;
    border: 1px solid #eee;
    margin: 0px 0px -1px -1px;
    min-height: 100%;
    overflow: hidden;
}

.my-event-area::-webkit-scrollbar {
    width: 0px;
}

.my-event-area::-webkit-scrollbar-track {
    display: none;
}
</style>
