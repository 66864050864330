<template>
<van-cell-group class="shadow-md" inset :title="titleText">
    <van-cell v-for="(session, index) in sessions" :key="index">
        <template #title>
            <div class="flex justify-start flex-wrap items-center">
                <div>
                    {{session.title}}
                </div>
            </div>
        </template>
        <template #label>
            <div>{{session.date}}</div>
        </template>
        <template #value>
            <span class="rounded p-1 text-white text-xs" :class="session.colorClass">{{session.time}}</span>
        </template>
    </van-cell>
</van-cell-group>
</template>

<script>
import {
    ref
} from "vue";
import {
    Cell,
    CellGroup
} from "vant";
import GetDays from "@/logics/GetDaysOfWeekInMonth.js";
import Helpers from "@/logics/Helpers.js";
import moment from "moment";

export default {
    components: {
        [Cell.name]: Cell,
        [CellGroup.name]: CellGroup
    },
    props: {
        classList: Array,
        langCode: {
            type: String,
            default: "zh"
        }
    },
    setup(props) {
        const colors = ["bg-pink-500", "bg-pink-600", "bg-pink-700",
            "bg-gray-500", "bg-gray-600", "bg-gray-700",
            "bg-red-500", "bg-red-600", "bg-red-700",
            "bg-yellow-500", "bg-yellow-600", "bg-yellow-700",
            "bg-green-500", "bg-green-600", "bg-green-700",
            "bg-blue-500", "bg-blue-600", "bg-blue-700",
            "bg-indigo-500", "bg-indigo-600", "bg-indigo-700",
            "bg-purple-500", "bg-purple-600", "bg-purple-700"
        ];

        const titleText = ref("本月日程");
        if (props.langCode !== "zh") {
            titleText.value = "Month Schedule";
        }

        const now = new Date();
        const year = now.getFullYear();
        const month = now.getMonth() + 1; //month: 1...12
        const sessions = ref([]);
        props.classList.forEach(function (classData) {
            if (!classData.teachers.length) {
                return;
            }

            const courseStart = moment(new Date(classData.classDates.start.year, classData.classDates.start.month - 1, classData.classDates.start.day));
            const courseEnd = moment(new Date(classData.classDates.end.year, classData.classDates.end.month - 1, classData.classDates.end.day));

            if (!classData.classTimes.length) {
                // const cursor = moment(new Date(classData.classDates.start.year, classData.classDates.start.month - 1, classData.classDates.start.day));
                // const nextMonth = moment(new Date(classData.classDates.end.year, month + 1, 1));
                // while (cursor.isBetween(courseStart, courseEnd, undefined, "[]") &&
                //     cursor.isBefore(nextMonth)) {
                //     sessions.value.push({
                //         title: classData.className,
                //         classId: classData.classId,
                //         time: "全天",
                //         date: Helpers.makeDateText(cursor.year(), cursor.month() + 1, cursor.date()),
                //         order: cursor.date() * 9999,
                //         colorClass: ["bg-black"]
                //     });
                //     cursor.add(1, "d");
                // }
                return;
            }

            GetDays(classData.classTimes, year, month, function (day, slot) {
                if (!moment(new Date(year, month - 1, day)).isBetween(courseStart, courseEnd, undefined, "[]")) {
                    return;
                }

                const hour = Math.floor(slot.timeStart / 100);
                const colorClass = [];
                colorClass.push(colors[hour]);

                sessions.value.push({
                    title: classData.className,
                    classId: classData.classId,
                    time: Helpers.makeTimeText(slot.timeStart),
                    date: Helpers.makeDateText(year, month, day),
                    duration: slot.duration,
                    order: day * 10000 + slot.timeStart,
                    colorClass
                });
            });
        });

        sessions.value.sort(function (a, b) {
            return a.order - b.order;
        });

        return {
            sessions,
            titleText
        };
    }
}
</script>
