import axios from "axios";
import CheckHttp from "@/asyncs/MyHttpCheck.js";
import {
    Notify
} from "vant";
import Env from "@/logics/Envs.js";

export default async function () {
    try {
        const res = await axios.get(Env.apiPath + "listClass");
        if (res.data.error) {
            Notify({
                type: "danger",
                message: res.data.error,
                duration: 5000
            });
            return [null, null];
        }
        return [res.data.list, res.data.studentState];
    } catch (err) {
        CheckHttp(err);
        return [null, null];
    }
}