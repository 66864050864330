import moment from "moment";

export default function (classTimes, year, month, gotDay) {
    classTimes.forEach(function (slot) {
        //month: 1...12
        const start = moment(new Date(year, month - 1, 1));
        start.day(slot.day);
        while (start.year() < year) {
            start.add(7, "d");
        }
        while (start.month() <= month - 1 && start.year() === year) {
            if (start.month() === month - 1) {
                gotDay(start.date(), slot);
            }
            start.add(7, "d");
        }
    });
}