export default {
    en: {
        title: "My Schedule",
        titleClass: "Total Classes",
        titleClassStart: "Started Classes",
        titleStudent: "Total Students",
        titleStudentActive: "Active Students",
        titleStudentNew: "New Students"
    },
    zh: {
        title: "我的主页",
        titleClass: "总课程",
        titleClassStart: "已开始课程",
        titleStudent: "总学生",
        titleStudentActive: "启用学生",
        titleStudentNew: "新注册学生"
    }
}